import React from 'react';
import Layout from '../components/Layout/layout';
import Contact from '../Views/Website/Contact/Contact';

const ContactPage: React.FC = () => {
  return (
    <Layout>
      <Contact hideBanner={false} />
    </Layout>
  );
};

export default ContactPage;
